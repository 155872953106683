var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "ユーザー名", prop: "name" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.name.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "phonetic",
                  attrs: { label: "ふりがな", prop: "phonetic" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.phonetic.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.phonetic,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phonetic", $$v)
                      },
                      expression: "form.phonetic"
                    }
                  })
                ],
                1
              ),
              _vm.submitType == "post"
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "email",
                      attrs: { label: "メールアドレス", prop: "email" }
                    },
                    [
                      _c("a-input", {
                        on: {
                          blur: function() {
                            _vm.$refs.email.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { ref: "role", attrs: { label: "権限", prop: "role" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "権限を選択してください。" },
                      model: {
                        value: _vm.form.role,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "role", $$v)
                        },
                        expression: "form.role"
                      }
                    },
                    _vm._l(_vm.roles, function(item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n          " + _vm._s(item.label) + "\n        "
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.form.role >= 2
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "所属支店" } },
                    [
                      _c("a-checkbox-group", {
                        attrs: { options: _vm.allBranches },
                        model: {
                          value: _vm.form.belong_branches,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "belong_branches", $$v)
                          },
                          expression: "form.belong_branches"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  ref: "fleet_flag",
                  attrs: {
                    label: "解析対象",
                    prop: "fleet_flag",
                    extra: "ロガーからのデータはすべて解析されます。"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.fleet_flag,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fleet_flag", $$v)
                        },
                        expression: "form.fleet_flag"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("解析対象にする")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("解析対象にしない")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "tel", attrs: { label: "電話番号", prop: "tel" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.tel.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "mobile_model",
                  attrs: { label: "スマホ機種", prop: "mobile_model" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.mobile_model.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.mobile_model,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mobile_model", $$v)
                      },
                      expression: "form.mobile_model"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "mobile_sim",
                  attrs: { label: "スマホのSIM", prop: "mobile_sim" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.mobile_sim.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.mobile_sim,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mobile_sim", $$v)
                      },
                      expression: "form.mobile_sim"
                    }
                  })
                ],
                1
              ),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "announcement_flag",
                      attrs: { label: "NOWの使用", prop: "announcement_flag" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.announcement_flag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "announcement_flag", $$v)
                            },
                            expression: "form.announcement_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("使用しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("使用する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "plus_flag",
                      attrs: { label: "PLUSの使用", prop: "plus_flag" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.plus_flag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "plus_flag", $$v)
                            },
                            expression: "form.plus_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("使用しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("使用する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "interlocking_detector",
                      attrs: {
                        label: "連動検知器の使用",
                        prop: "interlocking_detector"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.interlocking_detector,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "interlocking_detector", $$v)
                            },
                            expression: "form.interlocking_detector"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("使用しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("使用する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  ref: "roll_call_able",
                  attrs: { label: "点呼権限", prop: "roll_call_able" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.roll_call_able,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roll_call_able", $$v)
                        },
                        expression: "form.roll_call_able"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("権限に従う")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("支店管理者と同等にする")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "create_operator_flag",
                      attrs: {
                        label: "代行オペレーター作成可能",
                        prop: "create_operator_flag"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.create_operator_flag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "create_operator_flag", $$v)
                            },
                            expression: "form.create_operator_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("オペレーターの作成は不可")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("オペレーターの作成を可能")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myCreateOperator === 1
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "operator_flag",
                      attrs: {
                        label: "代行オペレーター",
                        prop: "operator_flag"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.operator_flag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "operator_flag", $$v)
                            },
                            expression: "form.operator_flag"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("オペレーターにしない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("オペレーターにする")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "roll_call_agent",
                      attrs: { label: "点呼代行対象", prop: "roll_call_agent" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.roll_call_agent,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "roll_call_agent", $$v)
                            },
                            expression: "form.roll_call_agent"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("対象にしない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("対象にする")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-config-provider",
                    { attrs: { locale: _vm.locale } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "roll_call_date_start",
                          attrs: {
                            label: "点呼代行開始日",
                            prop: "roll_call_date_start"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { placeholder: "点呼代行開始日" },
                            model: {
                              value: _vm.rollCallDateStart,
                              callback: function($$v) {
                                _vm.rollCallDateStart = $$v
                              },
                              expression: "rollCallDateStart"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-config-provider",
                    { attrs: { locale: _vm.locale } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "roll_call_date_end",
                          attrs: {
                            label: "点呼代行終了日",
                            prop: "roll_call_date_end"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { placeholder: "点呼代行終了日" },
                            model: {
                              value: _vm.rollCallDateEnd,
                              callback: function($$v) {
                                _vm.rollCallDateEnd = $$v
                              },
                              expression: "rollCallDateEnd"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "roll_call_count",
                      attrs: {
                        label: "点呼代行回数/月",
                        prop: "roll_call_count",
                        extra:
                          "点呼代行自体をしない場合は「0」にしておいてください。"
                      }
                    },
                    [
                      _c("a-input-number", {
                        attrs: { min: 0 },
                        model: {
                          value: _vm.form.roll_call_count,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "roll_call_count", $$v)
                          },
                          expression: "form.roll_call_count"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "roll_call_limited",
                      attrs: {
                        label: "代行利用制限",
                        prop: "roll_call_limited",
                        extra: "点呼代行ではないユーザーには影響しません。"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.roll_call_limited,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "roll_call_limited", $$v)
                            },
                            expression: "form.roll_call_limited"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("全ての点呼を代行にする")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("平日の日中は代行にしない")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("土日祝は代行にしない")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole <= 2 && _vm.isGoogle2fa === 1
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "is_google2fa",
                      attrs: { label: "2段階認証", prop: "is_google2fa" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.is_google2fa,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_google2fa", $$v)
                            },
                            expression: "form.is_google2fa"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("無効")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("有効")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.plus_flag === 1
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "allow_driving_schedule",
                      attrs: {
                        label: "運行計画表の被閲覧",
                        prop: "allow_driving_schedule"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.allow_driving_schedule,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "allow_driving_schedule", $$v)
                            },
                            expression: "form.allow_driving_schedule"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("許可しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("許可する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "use_violation_map",
                      attrs: {
                        label: "違反マップの使用",
                        prop: "use_violation_map"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.use_violation_map,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "use_violation_map", $$v)
                            },
                            expression: "form.use_violation_map"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("使用しない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("使用する")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.myRole === 0
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "hide_menu",
                      attrs: { label: "メニューを隠すか", prop: "hide_menu" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.form.hide_menu,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "hide_menu", $$v)
                            },
                            expression: "form.hide_menu"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("隠さない")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("隠す")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.freeItemUsers, function(item) {
                return _c(
                  "div",
                  { key: item.id },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: item.name } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.freeItemValues[item.id],
                            callback: function($$v) {
                              _vm.$set(_vm.freeItemValues, item.id, $$v)
                            },
                            expression: "freeItemValues[item.id]"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    {
                      class: _vm.$style.filledBtn,
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: _vm.backPath } },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }